import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaEdit,
  FaFileDownload,
  FaPlusSquare,
  FaUserAlt,
  FaUserCheck,
} from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { Heading } from "../../components/ui/Heading";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";
import { FormSearch } from "../../components/BigComponent/FormSearch";
import { Option } from "../../components/ui/Option";
import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import { Chip } from "../../components/ui/Chip";
import DeleteButton from "../../components/BigComponent/DeleteButton";
import { TitleList } from "../../components/BigComponent/TitleList";
import { FilterList } from "../../components/BigComponent/FilterList";

const ListCustomerTry = () => {
  const [authorities, setAuthorities] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getAuthorities();
  }, [page, keyword, limit]);

  const getAuthorities = async () => {
    const response = await axios.get(
      `${apiUrl}/customers?title=${keyword}&page=${page}&limit=${limit}`
    );

    setAuthorities(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = authorities.filter((item) => item.id !== id);
    setAuthorities(updatedData);
  };

  return (
    <Container>
      <TitleList
        title="Data Customers"
        icon={FaUserAlt}
        text="Add"
        rows={authorities.length}
        linkTo="/customers/add"
      />

      <div className="border rounded-lg pb-4">
        <div className="p-6">
          <FilterList
            onSubmitForm={searchData}
            value={query}
            onChangeItem={(e) => setQuery(e.target.value)}
            placeholder="Type Authorities title "
            options={options}
            onClickButtonOption={handleOptionChange}
            onClickButtonTrigers={toggleDropdown}
            isOpen={isOpen}
            limit={limit}
          />
        </div>
        <hr />
        <Table>
          <TableHead>
            <TableHeader HeaderTitle="No" />
            <TableHeader HeaderTitle="Image" />
            <TableHeader HeaderTitle="Name" />
            <TableHeader HeaderTitle="Status" />
            <TableHeader HeaderTitle="Action" />
          </TableHead>
          {authorities.length === 0 ? (
            <TableBody>
              <TableRows>
                <TableCells className="text-red-700 font-bold flex justify-center items-center mx-auto col-span-4">
                  <p> Tidak ada data yang ditemukan</p>
                </TableCells>
              </TableRows>
            </TableBody>
          ) : (
            <TableBody>
              {authorities.map((item, index) => (
                <TableRows key={item.id}>
                  <TableCells className="text-center"> {index + 1}</TableCells>
                  <TableCells>
                    <img src={item.url} alt="Image" className="w-24" />
                  </TableCells>
                  <TableCells> {item.title}</TableCells>
                  <TableCells className="text-center">
                    {item.status ? (
                      <Chip title="Active" className="bg-green-700" />
                    ) : (
                      <Chip title="Inactive" className="bg-red-700" />
                    )}
                  </TableCells>
                  <TableCells>
                    <div className="flex justify-center items-center">
                      <Link to={`/customers/edit/${item.id}`}>
                        <ButtonOnlyIcon icon={FaEdit} />
                      </Link>

                      <DeleteButton
                        id={item.id}
                        api="customers"
                        name={item.title}
                        title="Customer"
                        onDelete={handleDeleteButton}
                      />

                      {/* <Link to={`/authorities/edit/${item.id}`} className="px-2">
                      <button className="p-2 bg-orange-500 text-white hover:bg-orange-600 rounded-md">
                        <FaEye />
                      </button>
                    </Link> */}
                    </div>
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          )}
        </Table>
        <div className="flex gap-2 mx-auto justify-between px-6">
          <div className="flex gap-2">
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={handlePageChange}
            />
          </div>
          <RowsPagination rows={rows} page={page} pages={pages} />
        </div>
      </div>
    </Container>
  );
};

export default ListCustomerTry;
