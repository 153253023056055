import React from "react";

export const Paragraf = ({ label, item }) => {
  return (
    <>
      <p className="col-span-4">{label}</p>
      <p className="col-span-1">:</p>
      <p className="col-span-7">{item}</p>
    </>
  );
};
