import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaEdit, FaEye, FaFileDownload, FaPlusSquare } from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { Heading, HeadingTitleForm } from "../../components/ui/Heading";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";
import { FormSearch } from "../../components/BigComponent/FormSearch";
import { Option } from "../../components/ui/Option";
import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import { Chip } from "../../components/ui/Chip";
import DeleteButton from "../../components/BigComponent/DeleteButton";
import { PreviewImage } from "../../components/ui/FileImage";
import { Paragraf } from "../../components/ui/Paragraf";

const ListCompanyProfile = () => {
  const [company, setCompany] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getCompanyProfile();
  }, [page, keyword, limit]);

  const getCompanyProfile = async () => {
    const response = await axios.get(
      `${apiUrl}/company?name=${keyword}&page=${page}&limit=${limit}`
    );

    setCompany(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = company.filter((item) => item.id !== id);
    setCompany(updatedData);
  };
  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <Container>
      <div className="ck-content">
        <HeadingTitleForm title="Company Profile" />

        {company.map((item) => (
          <div>
            <PreviewImage
              preview={item.url}
              src={item.url}
              // srcNone={noImage}
            />
            <hr className="p-2" />
            <div className="grid grid-cols-12 gap-4 py-2">
              <Paragraf label="Web Name" item={item.webname} />
              <Paragraf label="Corporat Name" item={item.corporatname} />
              <Paragraf label="Welcome Note" item={item.welcomenote} />
              <Paragraf label="Description" item={item.description} />
              <Paragraf
                label="Address"
                item={`${item.address} ${item.village} village ${item.district} district ${item.city} city ${item.province} Postal Code ${item.postcode}`}
              />
              <Paragraf label="No Handphone" item={item.phone} />
              <Paragraf label="Fax" item={item.fax} />
              <Paragraf label="Web Name" item={item.webname} />
              <Paragraf label="Web Name" item={item.webname} />
              <h1 className="col-span-12">Media Sosial</h1>
              <Paragraf label="Instagram" item={item.instagram} />
              <Paragraf label="Youtube" item={item.youtube} />
              <Paragraf label="Linkedin" item={item.linkedin} />
            </div>

            <Link to={`/company/edit/${item.id}`} className="px-2">
              <ButtonWithIcon
                icon={FaEdit}
                text="Update Data"
                className="bg-blue-600 hover:bg-blue-700"
              />
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default ListCompanyProfile;
