import { useEffect, useState } from "react";
import { useRef } from "react";
import { motion } from "framer-motion";

// * React icons
import { IoIosArrowBack } from "react-icons/io";
import { SlSettings } from "react-icons/sl";
import { AiOutlineAppstore } from "react-icons/ai";
import { BsNewspaper, BsPerson } from "react-icons/bs";
import { HiOutlineDatabase } from "react-icons/hi";
import { TbReportAnalytics } from "react-icons/tb";
import { RiBuilding3Line } from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import {
  FaAppStore,
  FaBuilding,
  FaNewspaper,
  FaRegImage,
  FaSignOutAlt,
  FaSuitcase,
  FaTools,
  FaUserAlt,
  FaUserCheck,
  FaUserLock,
} from "react-icons/fa";

const Sidebar = () => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const remove = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };
  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };

  const subMenusList = [
    {
      name: "build",
      icon: RiBuilding3Line,
      menus: ["auth", "app settings", "stroage", "hosting"],
    },
    {
      name: "analytics",
      icon: TbReportAnalytics,
      menus: ["dashboard", "realtime", "events"],
    },
  ];

  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } `}
      ></div>
      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className=" bg-white text-gray z-[999] max-w-[16rem]  w-[16rem] 
            overflow-hidden md:relative fixed h-full border
        "
      >
        <div className="flex items-center gap-2.5 font-medium border-b py-3 border-slate-300  mx-3">
          <img src={Logo} width={45} alt="" />
          <span className="text-xl whitespace-pre">Jas Aero</span>
        </div>

        <div className="flex flex-col h-full">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100   md:h-[90%] h-[70%]">
            <li>
              <NavLink to={"/"} className="link">
                <AiOutlineAppstore size={23} className="min-w-max" />
                Dashboard
              </NavLink>
            </li>

            <li>
              <NavLink to={"/customers"} className="link">
                <FaUserAlt size={23} className="min-w-max" />
                Customer
              </NavLink>
            </li>
            <li>
              <NavLink to={"/authorities"} className="link">
                <FaUserCheck size={23} className="min-w-max" />
                Authorities
              </NavLink>
            </li>
            <li>
              <NavLink to={"/service"} className="link">
                <FaTools size={23} className="min-w-max" />
                Service
              </NavLink>
            </li>

            <li>
              <NavLink to={"/news"} className="link">
                <FaNewspaper size={23} className="min-w-max" />
                News & Activity
              </NavLink>
            </li>
            <li>
              <NavLink to={"/career"} className="link">
                <FaSuitcase size={23} className="min-w-max" />
                Career
              </NavLink>
            </li>
            {/* {(open || isTabletMid) && (
              <div className="border-y py-5 border-slate-300 ">
                <small className="pl-3 text-slate-500 inline-block mb-2">
                  Product categories
                </small>
                {subMenusList?.map((menu) => (
                  <div key={menu.name} className="flex flex-col gap-1">
                    <SubMenu data={menu} />
                  </div>
                ))}
              </div>
            )} */}
            {/* <li>
              <NavLink to={"/imageslider"} className="link">
                <FaRegImage size={23} className="min-w-max" />
                Image Slider
              </NavLink>
            </li>

            <li>
              <NavLink to={"/application"} className="link">
                <FaAppStore size={23} className="min-w-max" />
                Application
              </NavLink>
            </li>
            <li>
              <NavLink to={"/company"} className="link">
                <FaBuilding size={23} className="min-w-max" />
                Company
              </NavLink>
            </li> */}
            <li>
              <NavLink to={"/user"} className="link">
                <FaUserLock size={23} className="min-w-max" />
                Users
              </NavLink>
            </li>
            <li>
              <NavLink to={"/logout"} className="link">
                <FaSignOutAlt size={23} className="min-w-max" />
                <button onClick={remove}>Logout</button>
              </NavLink>
            </li>
            {/* <li>
              <motion.div
                onClick={() => {
                  setOpen(!open);
                }}
                animate={
                  open
                    ? {
                        x: 0,
                        y: 0,
                        rotate: 0,
                      }
                    : {
                        x: -10,
                        y: -200,
                        rotate: 180,
                      }
                }
                transition={{ duration: 0 }}
                className="absolute w-fit md:block z-50 hidden right-10 cursor-pointer"
              >
                <IoIosArrowBack size={25} />
              </motion.div>
            </li> */}
          </ul>
          {/* {open && (
            <div className="flex-1 text-sm z-50  max-h-48 my-auto  whitespace-pre   w-full  font-medium  ">
              <div className="flex border-y border-slate-300 p-4 items-center justify-between">
                <div>
                  <p>Spark</p>
                  <small>No-cost $0/month</small>
                </div>
                <p className="text-teal-500 py-1.5 px-3 text-xs bg-teal-50 rounded-xl">
                  Upgrade
                </p>
              </div>
            </div>
          )} */}
        </div>
      </motion.div>
      <div className="m-3 md:hidden  " onClick={() => setOpen(true)}>
        <MdMenu size={25} />
      </div>
    </div>
  );
};

export default Sidebar;
