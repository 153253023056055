import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { FaImage, FaSave } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContainerForm } from "../../components/ui/Container";

const AddImageSlider = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [status, setStatus] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const saveCareer = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("subtitle", subtitle);
    formData.append("status", status);
    try {
      await axios.post(`${apiUrl}/hero`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/imageslider");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
  };

  return (
    <ContainerForm>
      <HeadingTitleForm
        title="Add New Image Slider"
        icon={FaImage}
        color="text-blue-500"
      />
      <Form
        onSubmit={saveCareer}
        className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4 rounded-md border place-items-start "
      >
        <Textfield
          type="text"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
        />
        <Textfield
          type="text"
          label="Subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
          placeholder="Title"
        />
        <div>
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
        </div>
        <Checkbox
          type="checkbox"
          name="status"
          title="status"
          checked={status}
          onChange={handleCheckboxChange}
          alert="Do you want to show this Image Slider at Website?"
        />
        <div className="col-span-2">
          <p>Deskripsi</p>
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Save"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default AddImageSlider;
