import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaNewspaper, FaSave } from "react-icons/fa";
import DateTime from "../../components/ui/DateTime";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContainerForm } from "../../components/ui/Container";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const EditNews = () => {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [volno, setVolno] = useState("");
  const [newsslug, setNewsSlug] = useState("");
  const [datetime, setDatetime] = useState(new Date());
  const [image, setImage] = useState("");
  const [status, setStatus] = useState();
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getNewsById();
  }, []);

  const getNewsById = async () => {
    const response = await axios.get(`${apiUrl}/news/${id}`);
    setHeadline(response.data.headline);
    setDescription(response.data.description);
    setVolno(response.data.volno);
    setNewsSlug(response.data.newsslug);
    setDatetime(response.data.datetime);
    setStatus(response.data.status);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateNews = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("headline", headline);
    formData.append("volno", volno);
    formData.append("description", description);
    formData.append("datetime", datetime);
    formData.append("newsslug", newsslug);
    formData.append("image", image);
    formData.append("status", status);
    try {
      await axios.put(`${apiUrl}/news/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/news");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
    console.log(status);
  };

  const handleDateChange = (date) => {
    setDatetime(date);
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb pathLink="/news" pathName="News" pageName="Edit News" />
      <Form onSubmit={updateNews} title="Edit News">
        <Textfield
          type="text"
          label="Headline"
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          placeholder="News Headline"
        />

        <Textfield
          type="text"
          label="News Slug"
          value={newsslug}
          onChange={(e) =>
            setNewsSlug(headline.split(" ").join("-").toLocaleLowerCase())
          }
          placeholder="News Slug"
        />

        <Textfield
          type="text"
          label="Volume No"
          value={volno}
          onChange={(e) => setVolno(e.target.value)}
          placeholder="Volume no"
        />

        <DateTime
          selected={new Date(datetime)}
          onChange={handleDateChange}
          label="Date"
        />

        <div>
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
        </div>

        <Checkbox
          type="checkbox"
          name="type"
          title="Choose type Article:"
          checked={status}
          onChange={handleCheckboxChange}
          alert="What is the type of this Article?"
          positif="News"
          negative="Activity"
        />
        <di className="col-span-2">
          <p>Deskripsi</p>
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </di>

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Save"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default EditNews;
