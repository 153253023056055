import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { FaNewspaper, FaSave } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import DateTime from "../../components/ui/DateTime";
import RichTextEditor from "../../components/ui/RichText";
import { ContainerForm } from "../../components/ui/Container";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const AddNews = () => {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [volno, setVolno] = useState("");
  const [newsslug, setNewsSlug] = useState("");
  const [datetime, setDatetime] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState();
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const saveNews = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("headline", headline);
    formData.append("volno", volno);
    formData.append("description", description);
    formData.append("datetime", datetime);
    formData.append("newsslug", newsslug);
    formData.append("image", image);
    formData.append("status", status);
    try {
      await axios.post(`${apiUrl}/news`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });

      navigate("/news");
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditorDataChange = () => {
    setDescription(description);
  };

  // const handleCheckboxChange = () => {
  //   setStatus(!status);
  // };

  const slugsplit = () => {
    setNewsSlug(headline.split(" ").join("-").toLocaleLowerCase());
  };
  const handleDateChange = (date) => {
    setDatetime(date);
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb pathLink="/news" pathName="News" pageName="Add News" />
      <Form onSubmit={saveNews} title="Add News">
        <Textfield
          type="text"
          label="Headline"
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          placeholder="News Headline"
        />

        <Textfield
          type="text"
          label="News Slug"
          value={newsslug}
          onChange={(e) =>
            setNewsSlug(headline.split(" ").join("-").toLocaleLowerCase())
          }
          placeholder="News Slug"
        />

        <Textfield
          type="text"
          label="Volume No"
          value={volno}
          onChange={(e) => setVolno(e.target.value)}
          placeholder="Volume no"
        />

        {/* <DatePicker
          showIcon
          selected={datetime}
          onChange={(date) => setDatetime(date)}
        /> */}

        <DateTime
          selected={datetime}
          onChange={handleDateChange}
          label="Date"
        />

        <div>
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
        </div>

        <Checkbox
          type="checkbox"
          name="type"
          title="Choose type Article:"
          checked={status}
          onChange={handleCheckboxChange}
          alert="What is the type of this Article?"
          positif="News"
          negative="Activity"
        />
        <div className="col-span-2">
          <p>Deskripsi</p>
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Save"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default AddNews;
