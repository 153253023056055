import React from "react";
import { motion } from "framer-motion";

const Card = ({ icon: Icon, title, count, className, color }) => {
  return (
    <motion.div
      className={`flex justify-center items-center gap-2 rounded-lg overflow-hidden w-56 ${className}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {Icon && <Icon className={`w-12 h-12 mr-2 ${color}`} />}
      <div className="p-4">
        <motion.h3 className="text-base">{title}</motion.h3>
        <motion.p className="text-gray-600 font-bold text-2xl text-center">
          {count}
        </motion.p>
      </div>
    </motion.div>
  );
};

export default Card;
