import React from "react";
import { motion } from "framer-motion";

export const Heading = ({ title, datarows, icon: Icon, color, className }) => {
  return (
    <div
      className={`flex items-center px-6 rounded-lg overflow-hidden w-full ${className}`}
    >
      {Icon && <Icon className={`w-12 h-12 ${color}`} />}
      <div className="flex items-center p-4 gap-2">
        <h3 className={`text-xl font-semibold  ${color}`}>{title}</h3>{" "}
        <span
          className={`text-xl font-semibold border-2 px-1 rounded-md   ${color}`}
        >
          {datarows}
        </span>
      </div>
    </div>
  );
};
export const HeadingTitleForm = ({
  icon: Icon,
  title,
  subtitle,
  className,
  color,
}) => {
  return (
    <div
      className={`flex mt-4 gap-2 rounded-lg overflow-hidden w-full ${className}`}
    >
      {Icon && <Icon className={`w-12 h-12 mr-2 ${color}`} />}
      <div className="p-4">
        <motion.h3 className="text-2xl">{title}</motion.h3>
      </div>
    </div>
  );
};
