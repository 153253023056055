import React, { useState } from "react";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import { ButtonOnlyIcon } from "../ui/Button";
const apiUrl = process.env.REACT_APP_API;

function DeleteButton({ title, name, id, api, onDelete, publicId }) {
  const [isConfirming, setIsConfirming] = useState(false);

  const confirmDelete = () => {
    setIsConfirming(true);
  };

  const cancelDelete = () => {
    setIsConfirming(false);
  };

  const handleDelete = async () => {
    axios
      .delete(`${apiUrl}/${api}/${id}`) // Replace with the appropriate API endpoint
      .then(() => {
        onDelete(id); // Call the onDelete function to remove the data from the UI
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
    setIsConfirming(false);
  };

  return (
    <div>
      <ButtonOnlyIcon
        icon={FaTrash}
        onClick={confirmDelete}
        // className="bg-red-600 hover:bg-red-700"
      />
      {isConfirming && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-30">
          <div className="bg-white w-96 p-4 rounded-lg shadow-md">
            <p className="font-bold py-4">Confirmation:</p>
            <div className=" bg-red-200 p-2 rounded-md">
              <span>
                Are you sure you want to delete this {title}{" "}
                <span className="font-semibold">{name}?</span>
              </span>
            </div>

            <div className="flex justify-end mt-4">
              <button
                onClick={cancelDelete}
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-4"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 hover.bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteButton;
