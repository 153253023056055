import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaSave, FaUserLock } from "react-icons/fa";
import { ContainerForm } from "../../components/ui/Container";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const EditAuthorities = () => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState();
  const [country, setCountry] = useState();
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getAuthoritiesById();
  }, []);

  const getAuthoritiesById = async () => {
    const response = await axios.get(`${apiUrl}/authorities/${id}`);
    setTitle(response.data.title);
    setStatus(response.data.status);
    setCountry(response.data.country);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateAuthorities = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("status", status);
    formData.append("country", country);
    formData.append("title", title);
    try {
      await axios.put(`${apiUrl}/authorities/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/authorities");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
    console.log(status);
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/authoties"
        pathName="Authorities"
        pageName="Edit Authorities"
      />
      <Form onSubmit={updateAuthorities} title="Edit Authorities">
        <Textfield
          type="text"
          label="Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Authorities Name"
        />

        <Textfield
          type="text"
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="Country"
        />

        <div>
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
        </div>

        <Checkbox
          type="checkbox"
          name="status"
          title="status"
          checked={status}
          onChange={handleCheckboxChange}
          alert="Is this Authorities still Active?"
        />

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Save"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default EditAuthorities;
