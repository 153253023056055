import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaEdit,
  FaEye,
  FaFileDownload,
  FaPlusSquare,
  FaSuitcase,
} from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { Heading } from "../../components/ui/Heading";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";
import { FormSearch } from "../../components/BigComponent/FormSearch";
import { Option } from "../../components/ui/Option";
import { motion } from "framer-motion";
import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import { Chip } from "../../components/ui/Chip";
import DeleteButton from "../../components/BigComponent/DeleteButton";
import { TitleList } from "../../components/BigComponent/TitleList";
import { FilterList } from "../../components/BigComponent/FilterList";

const ListCareer = () => {
  const [career, setCareer] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getCareer();
  }, [page, keyword, limit]);

  const getCareer = async () => {
    const response = await axios.get(
      `${apiUrl}/career?title=${keyword}&page=${page}&limit=${limit}`
    );

    setCareer(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = career.filter((item) => item.id !== id);
    setCareer(updatedData);
  };
  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <Container>
      <TitleList
        title="Data Career"
        icon={FaSuitcase}
        text="Add"
        rows={career.length}
        linkTo="/career/add"
      />

      <div className="border rounded-lg pb-4">
        <div className="p-6">
          <FilterList
            onSubmitForm={searchData}
            value={query}
            onChangeItem={(e) => setQuery(e.target.value)}
            placeholder="Type Job Position"
            options={options}
            onClickButtonOption={handleOptionChange}
            onClickButtonTrigers={toggleDropdown}
            isOpen={isOpen}
            limit={limit}
          />
        </div>
        <hr />

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Table>
            <TableHead>
              <TableHeader HeaderTitle="No" />
              <TableHeader HeaderTitle="Image" />
              <TableHeader HeaderTitle="Name" />
              {/* <TableHeader HeaderTitle="Description" /> */}
              <TableHeader HeaderTitle="Closing Date" />
              <TableHeader HeaderTitle="Status" />
              <TableHeader HeaderTitle="Action" />
            </TableHead>
            {career.length === 0 ? (
              <TableBody>
                <TableRows>
                  <TableCells className="text-red-700 font-bold flex justify-center items-center mx-auto col-span-4">
                    <p> Tidak ada data yang ditemukan</p>
                  </TableCells>
                </TableRows>
              </TableBody>
            ) : (
              <TableBody>
                {career.map((item, index) => (
                  <TableRows key={item.id}>
                    <TableCells className="text-center">
                      {" "}
                      {index + 1}
                    </TableCells>
                    <TableCells>
                      <img src={item.url} alt="Image" className="w-24" />
                    </TableCells>
                    <TableCells> {item.title}</TableCells>
                    {/* <TableCells>
                    <p className="line-clamp-2"> {item.description}</p>
                  </TableCells> */}
                    <TableCells> {formatClosing(item.closingdate)}</TableCells>
                    <TableCells className="text-center">
                      {item.status ? (
                        <Chip title="Active" className="bg-green-700" />
                      ) : (
                        <Chip title="Inactive" className="bg-red-700" />
                      )}
                    </TableCells>
                    <TableCells>
                      <div className="flex justify-center items-center">
                        <Link to={`/career/edit/${item.id}`}>
                          <ButtonOnlyIcon icon={FaEdit} />
                        </Link>

                        <DeleteButton
                          id={item.id}
                          api="career"
                          name={item.title}
                          title="Job Vacancies"
                          onDelete={handleDeleteButton}
                        />

                        <Link to={`/career/view/${item.id}`}>
                          <ButtonOnlyIcon icon={FaEye} />
                        </Link>
                      </div>
                    </TableCells>
                  </TableRows>
                ))}
              </TableBody>
            )}
          </Table>
          <div className="flex gap-2 mx-auto justify-between px-6">
            <div className="flex gap-2">
              <Pagination
                currentPage={page}
                totalPages={pages}
                onPageChange={handlePageChange}
              />
            </div>
            <RowsPagination page={page} pages={pages} />
          </div>
        </motion.div>
      </div>
    </Container>
  );
};

export default ListCareer;
