import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { FaSave, FaSuitcase } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import DateTime from "../../components/ui/DateTime";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContainerForm } from "../../components/ui/Container";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const AddCareer = () => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState();
  const [description, setDescription] = useState();
  const [closingdate, setClosingDate] = useState(new Date());
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const saveCareer = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("closingdate", closingdate);
    formData.append("status", status);
    try {
      await axios.post(`${apiUrl}/career`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/career");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
  };

  const handleDateChange = (date) => {
    setClosingDate(date);
  };

  const handleEditorDataChange = () => {
    setDescription(description);
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/career"
        pathName="Career"
        pageName="Add Career"
      />
      <Form onSubmit={saveCareer} title="Add New Career">
        <Textfield
          type="text"
          label="Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Career Title"
        />
        <DateTime
          selected={closingdate}
          onChange={(e) => handleDateChange(closingdate)}
          label="Closing Date"
        />
        <div>
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
        </div>

        <Checkbox
          type="checkbox"
          name="status"
          title="status"
          checked={status}
          onChange={handleCheckboxChange}
          alert="Is this Service still Active?"
          positif="Yes"
          negative="No"
        />
        <div className="col-span-2">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Save"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default AddCareer;
