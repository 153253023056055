import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Form } from "../../components/ui/Form";
import noImage from "../../assets/noimage.png";
import { Textfield } from "../../components/ui/Textfield";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import { FaEdit, FaSave } from "react-icons/fa";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";

const EditCompanyProfile = () => {
  const [webname, setWebname] = useState("");
  const [corporatname, setCorporateName] = useState("");
  const [address, setAddress] = useState("");
  const [village, setVillage] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postcode, setPostCode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [url, setUrl] = useState("");
  const [youtube, setYoutube] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLingkedin] = useState("");
  const [welcomenote, setWelcomeNote] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const UpdateCompanyProfile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("webname", webname);
    formData.append("corporatname", corporatname);
    formData.append("address", address);
    formData.append("village", village);
    formData.append("district", district);
    formData.append("city", city);
    formData.append("province", province);
    formData.append("postcode", postcode);
    formData.append("phone", phone);
    formData.append("fax", fax);
    formData.append("url", url);
    formData.append("youtube", youtube);
    formData.append("instagram", instagram);
    formData.append("linkedin", linkedin);
    formData.append("welcomenote", welcomenote);
    formData.append("description", description);
    formData.append("image", image);
    try {
      await axios.put(`http://localhost:5000/company/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/company");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyById();
  }, []);

  const getCompanyById = async () => {
    const response = await axios.get(`http://localhost:5000/company/${id}`);
    setWebname(response.data.webname);
    setCorporateName(response.data.corporatname);
    setAddress(response.data.address);
    setVillage(response.data.village);
    setDistrict(response.data.district);
    setCity(response.data.city);
    setProvince(response.data.province);
    setPostCode(response.data.postcode);
    setPhone(response.data.phone);
    setFax(response.data.fax);
    setUrl(response.data.url);
    setYoutube(response.data.youtube);
    setInstagram(response.data.instagram);
    setLingkedin(response.data.linkedin);
    setWelcomeNote(response.data.welcomenote);
    setDescription(response.data.description);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  // const handleCheckboxChange = () => {
  //   setStatus(!status);
  //   console.log(status);
  // };

  return (
    <div className="flex flex-col gap-5 py-6 px-6">
      <h1 className="font-semibold text-2xl my-6">Company Identity</h1>
      <Form
        onSubmit={UpdateCompanyProfile}
        title="Company Identity"
        className="flex flex-col mx-auto"
      >
        <div className="grid grid-cols-2 gap-2 place-items-start p-4">
          <div className="col-span-2 font-bold">Company Identity</div>
          <Textfield
            type="text"
            label="Website Name"
            value={webname}
            onChange={(e) => setWebname(e.target.value)}
            placeholder="Website Name"
          />
          <Textfield
            type="text"
            label="Corporate Name"
            value={corporatname}
            onChange={(e) => setCorporateName(e.target.value)}
            placeholder="Corporate Name"
          />

          <Textfield
            type="text"
            label="Welcome Note"
            value={welcomenote}
            onChange={(e) => setWelcomeNote(e.target.value)}
            placeholder="Corporate Name"
          />
          <Textfield
            type="text"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
          <div className="col-span-2 font-bold">Office Address</div>
          <Textfield
            type="text"
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
            className="col-span-2"
          />
          <Textfield
            type="text"
            label="Village"
            value={village}
            onChange={(e) => setVillage(e.target.value)}
            placeholder="Village"
          />
          <Textfield
            type="text"
            label="District"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            placeholder="District"
          />
          <Textfield
            type="text"
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="City"
          />
          <Textfield
            type="text"
            label="Province"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            placeholder="Province"
          />
          <Textfield
            type="text"
            label="Postal Code"
            value={postcode}
            onChange={(e) => setPostCode(e.target.value)}
            placeholder="Postal Code"
          />
          <div className="col-span-2 font-bold">Contact and Medsos</div>
          <Textfield
            type="text"
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
          />
          <Textfield
            type="text"
            label="Fax"
            value={fax}
            onChange={(e) => setFax(e.target.value)}
            placeholder="Fax"
          />

          <Textfield
            type="text"
            label="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL"
          />
          <Textfield
            type="text"
            label="Youtube"
            value={youtube}
            onChange={(e) => setYoutube(e.target.value)}
            placeholder="Youtube"
          />
          <Textfield
            type="text"
            label="Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            placeholder="Instagram"
          />
          <Textfield
            type="text"
            label="Linkedin"
            value={linkedin}
            onChange={(e) => setLingkedin(e.target.value)}
            placeholder="Linkedin"
          />
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
          <div className="flex gap-4">
            <ButtonWithIcon
              type="submit"
              icon={FaEdit}
              text="Update Data"
              className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditCompanyProfile;
