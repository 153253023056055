import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaEdit,
  FaEye,
  FaFileDownload,
  FaImage,
  FaPlusSquare,
} from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { Heading } from "../../components/ui/Heading";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";
import { FormSearch } from "../../components/BigComponent/FormSearch";
import { Option } from "../../components/ui/Option";
import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import { Chip } from "../../components/ui/Chip";
import DeleteButton from "../../components/BigComponent/DeleteButton";

const ListImageSlider = () => {
  const [hero, setHero] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getHero();
  }, [page, keyword, limit]);

  const getHero = async () => {
    const response = await axios.get(
      `${apiUrl}/hero?title=${keyword}&page=${page}&limit=${limit}`
    );

    setHero(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = hero.filter((item) => item.id !== id);
    setHero(updatedData);
  };
  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <Container>
      <Heading
        title="Image Slider"
        datarows={rows}
        icon={FaImage}
        color="text-blue-600"
      />
      <div className="flex justify-end gap-4 px-6 py-4">
        <Link to="/imageslider/add">
          <ButtonWithIcon
            icon={FaPlusSquare}
            text="New Image Slider"
            className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300"
          />
        </Link>
        {/* <Link to="/imageslider/add">
          <ButtonWithIcon
            icon={FaFileDownload}
            text="Export"
            className=" bg-green-600 hover:bg-green-700 focus:ring-green-300"
          />
        </Link> */}
      </div>
      <div className="flex gap-2 justify-between">
        <FormSearch
          onSubmitForm={searchData}
          type="text"
          value={query}
          onChangeItem={(e) => setQuery(e.target.value)}
          placeholder="Type title "
        />
        <Option
          title="Show Of Entries"
          options={options}
          onClickButtonOption={handleOptionChange}
          onClickButtonTrigers={toggleDropdown}
          isOpen={isOpen}
          limit={limit}
        />
      </div>

      <Table>
        <TableHead>
          <TableHeader HeaderTitle="No" />
          <TableHeader HeaderTitle="Image" />
          <TableHeader HeaderTitle="Title" />
          <TableHeader HeaderTitle="Subtitle" />
          <TableHeader HeaderTitle="Description" />
          <TableHeader HeaderTitle="Status" />
          <TableHeader HeaderTitle="Action" />
        </TableHead>
        {hero.length === 0 ? (
          <TableBody>
            <TableRows>
              <TableCells className="text-red-700 font-bold flex justify-center items-center mx-auto col-span-4">
                <p> Tidak ada data yang ditemukan</p>
              </TableCells>
            </TableRows>
          </TableBody>
        ) : (
          <TableBody>
            {hero.map((item, index) => (
              <TableRows key={item.id}>
                <TableCells className="text-center"> {index + 1}</TableCells>
                <TableCells>
                  <img src={item.url} alt="Image" className="w-24" />
                </TableCells>
                <TableCells> {item.title}</TableCells>
                <TableCells> {item.subtitle}</TableCells>
                <TableCells> {item.description}</TableCells>
                <TableCells className="text-center">
                  {item.status ? (
                    <Chip title="Active" className="bg-green-700" />
                  ) : (
                    <Chip title="Inactive" className="bg-red-700" />
                  )}
                </TableCells>
                <TableCells>
                  <div className="flex justify-center items-center">
                    <Link to={`/imageslider/edit/${item.id}`} className="px-2">
                      <ButtonOnlyIcon
                        icon={FaEdit}
                        className="bg-blue-600 hover:bg-blue-700"
                      />
                    </Link>
                    <DeleteButton
                      id={item.id}
                      api="hero"
                      name={item.title}
                      title="Image Slider"
                      onDelete={handleDeleteButton}
                    />

                    <Link to={`/imageslider/view/${item.id}`} className="px-2">
                      <button className="p-2 bg-orange-500 text-white hover:bg-orange-600 rounded-md">
                        <FaEye />
                      </button>
                    </Link>
                  </div>
                </TableCells>
              </TableRows>
            ))}
          </TableBody>
        )}
      </Table>
      <div className="flex gap-2 mx-auto justify-between px-6">
        <div className="flex gap-2">
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={handlePageChange}
          />
        </div>
        <RowsPagination page={page} pages={pages} />
      </div>
    </Container>
  );
};

export default ListImageSlider;
