import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaAppStore,
  FaEdit,
  FaFileDownload,
  FaPlusSquare,
} from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { Heading } from "../../components/ui/Heading";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";
import { FormSearch } from "../../components/BigComponent/FormSearch";
import { Option } from "../../components/ui/Option";
import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import { Chip } from "../../components/ui/Chip";
import DeleteButton from "../../components/BigComponent/DeleteButton";

const ListApplication = () => {
  const [application, setApplication] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getApplication();
  }, [page, keyword, limit]);

  const getApplication = async () => {
    const response = await axios.get(
      `${apiUrl}/application?name=${keyword}&page=${page}&limit=${limit}`
    );

    setApplication(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = application.filter((item) => item.id !== id);
    setApplication(updatedData);
  };

  return (
    <Container>
      <Heading
        title="Application"
        datarows={rows}
        icon={FaAppStore}
        color="text-blue-600"
      />
      <div className="flex justify-end gap-4 px-6 py-4">
        <Link to="/application/add">
          <ButtonWithIcon
            icon={FaPlusSquare}
            text="New Application"
            className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300"
          />
        </Link>
        {/* <Link to="/application/add">
          <ButtonWithIcon
            icon={FaFileDownload}
            text="Export"
            className=" bg-green-600 hover:bg-green-700 focus:ring-green-300"
          />
        </Link> */}
      </div>
      <div className="flex gap-2 justify-between">
        <FormSearch
          onSubmitForm={searchData}
          type="text"
          value={query}
          onChangeItem={(e) => setQuery(e.target.value)}
          placeholder="Type Application name "
        />
        <Option
          title="Show Of Entries"
          options={options}
          onClickButtonOption={handleOptionChange}
          onClickButtonTrigers={toggleDropdown}
          isOpen={isOpen}
          limit={limit}
        />
      </div>

      <Table>
        <TableHead>
          <TableHeader HeaderTitle="Name" />
          <TableHeader HeaderTitle="Shortname" />
          <TableHeader HeaderTitle="URL" />
          <TableHeader HeaderTitle="Logo" />
          <TableHeader HeaderTitle="Action" />
        </TableHead>
        {application.length === 0 ? (
          <TableCells className="text-red-700 font-bold flex justify-center items-center mx-auto col-span-4">
            <p> Tidak ada data yang ditemukan</p>
          </TableCells>
        ) : (
          <TableBody>
            {application.map((item) => (
              <TableRows>
                <TableCells> {item.name}</TableCells>
                <TableCells> {item.shortname}</TableCells>
                <TableCells> {item.url}</TableCells>
                <TableCells> {item.image}</TableCells>
                <TableCells>
                  <div className="flex justify-center items-center">
                    <Link to={`/application/edit/${item.id}`} className="px-2">
                      <ButtonOnlyIcon
                        icon={FaEdit}
                        className="bg-blue-600 hover:bg-blue-700"
                      />
                    </Link>
                    <DeleteButton
                      id={item.id}
                      api="application"
                      name={item.name}
                      title="Application"
                      onDelete={handleDeleteButton}
                    />

                    {/* <Link to={`/application/edit/${item.id}`} className="px-2">
                      <button className="p-2 bg-orange-500 text-white hover:bg-orange-600 rounded-md">
                        <FaEye />
                      </button>
                    </Link> */}
                  </div>
                </TableCells>
              </TableRows>
            ))}
          </TableBody>
        )}
      </Table>
      <div className="flex gap-2 mx-auto justify-between px-6">
        <div className="flex gap-2">
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={handlePageChange}
          />
        </div>
        <RowsPagination rows={rows} page={page} pages={pages} />
      </div>
    </Container>
  );
};

export default ListApplication;
