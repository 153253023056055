import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaSave } from "react-icons/fa";
import DateTime from "../../components/ui/DateTime";
import { Container } from "../../components/ui/Container";

const ViewImageSlider = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [status, setStatus] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getCareerById();
  }, []);

  const getCareerById = async () => {
    const response = await axios.get(`${apiUrl}/hero/${id}`);
    setTitle(response.data.title);
    setSubTitle(response.data.subtitle);
    setStatus(response.data.status);
    setDescription(response.data.description);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const formatClosing = (data) => {
    const formatClosingDate = moment(data).format("DD MMMM YYYY");
    return formatClosingDate;
  };

  return (
    <Container>
      <div className="ck-content">
        <HeadingTitleForm title="Image Slider Detail" />
        <div className="flex gap-10">
          <PreviewImage preview={preview} src={preview} srcNone={noImage} />
          <div className="flex flex-col">
            <div className="grid grid-cols-2 py-2">
              <p>Title</p>
              <p>: {title}</p>
            </div>
            <div className="grid grid-cols-2 py-2">
              <p>Subtitle</p>
              <p>: {subtitle}</p>
            </div>

            <div className="grid grid-cols-2 pt-2 pb-10">
              <p>Type</p>
              {status ? <p>: Aktif</p> : <p>: Inactive</p>}
            </div>
            <p> {description}</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewImageSlider;
