import { BrowserRouter, Routes, Route } from "react-router-dom";
import SidebarLayout from "./components/Navigation/SidebarLayout";
import ListAuthorities from "./page/autorities/ListAuthorities";
import AddAuthorities from "./page/autorities/AddAuthorities";
import EditAuthorities from "./page/autorities/EditAuthorities";
import ListServices from "./page/service/ListService";
import AddService from "./page/service/AddService";
import EditService from "./page/service/EditService";
import ListCareer from "./page/career/ListCareer";
import AddCareer from "./page/career/AddCareer";
import EditCareer from "./page/career/EditCareer";
import ViewCareer from "./page/career/ViewCareer";
import ListImageSlider from "./page/imageslider/ListImageSlider";
import AddImageSlider from "./page/imageslider/AddImageSlider";
import EditImageSlider from "./page/imageslider/EditImageSlider";
import ViewImageSlider from "./page/imageslider/ViewImageSlider";
import ListApplication from "./page/application/ListApplication";
import AddApplication from "./page/application/AddApplication";
import EditApplication from "./page/application/EditApplication";
import ListNews from "./page/news/ListNews";
import AddNews from "./page/news/AddNews";
import Dashboard from "./page/dashboard/Dashboard";
import ViewNews from "./page/news/ViewNews";
import EditNews from "./page/news/EditNews";

import ViewService from "./page/service/ViewService";
import AddCompanyProfile from "./page/company/AddCompany";
import ListCompanyProfile from "./page/company/ListCompany";
import EditCompanyProfile from "./page/company/EditCompany";
import Login from "./page/user/Login";
import ListUsers from "./page/user/ListUser";
import AddUser from "./page/user/AddUser";
import EditUser from "./page/user/EditUser";
import AddCustomer from "./page/customer/AddCustomers";
import EditCustomers from "./page/customer/EditCustomers";
import ListCustomerTry from "./page/customer/ListCustomer";

function App({ children }) {
  const token = localStorage.getItem("token");
  return (
    <BrowserRouter>
      {token ? (
        <SidebarLayout>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/customers" element={<ListCustomerTry />} />
            <Route path="/customers/add" element={<AddCustomer />} />
            <Route path="/customers/edit/:id" element={<EditCustomers />} />

            <Route path="/authorities" element={<ListAuthorities />} />
            <Route path="/authorities/add" element={<AddAuthorities />} />
            <Route path="/authorities/edit/:id" element={<EditAuthorities />} />

            <Route path="/service" element={<ListServices />} />
            <Route path="/service/add" element={<AddService />} />
            <Route path="/service/edit/:id" element={<EditService />} />
            <Route path="/service/view/:id" element={<ViewService />} />

            <Route path="/career" element={<ListCareer />} />
            <Route path="/career/add" element={<AddCareer />} />
            <Route path="/career/edit/:id" element={<EditCareer />} />
            <Route path="/career/view/:id" element={<ViewCareer />} />

            <Route path="/imageslider" element={<ListImageSlider />} />
            <Route path="/imageslider/add" element={<AddImageSlider />} />
            <Route path="/imageslider/edit/:id" element={<EditImageSlider />} />
            <Route path="/imageslider/view/:id" element={<ViewImageSlider />} />

            <Route path="/application" element={<ListApplication />} />
            <Route path="/application/add" element={<AddApplication />} />
            <Route path="/application/edit/:id" element={<EditApplication />} />

            <Route path="/news" element={<ListNews />} />
            <Route path="/news/add" element={<AddNews />} />
            <Route path="/news/edit/:id" element={<EditNews />} />
            <Route path="/news/view/:id" element={<ViewNews />} />

            <Route path="/company" element={<ListCompanyProfile />} />
            <Route path="/company/add" element={<AddCompanyProfile />} />
            <Route path="/company/edit/:id" element={<EditCompanyProfile />} />

            <Route path="/user" element={<ListUsers />} />
            <Route path="/user/add" element={<AddUser />} />
            <Route path="/user/edit/:id" element={<EditUser />} />
          </Routes>
        </SidebarLayout>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
    // <div className="flex gap-5">
    //   <Sidebar />
    //   <main className="max-w-5xl flex-1 mx-auto py-4">{children}</main>
    // </div>
  );
}

export default App;
