import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaSave } from "react-icons/fa";
import DateTime from "../../components/ui/DateTime";
import { Container } from "../../components/ui/Container";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";
import { Chip } from "../../components/ui/Chip";

const ViewService = () => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState();
  const [description, setDescription] = useState("");
  const [detailcontent, setDetailContent] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getCareerById();
  }, []);

  const getCareerById = async () => {
    const response = await axios.get(`${apiUrl}/services/${id}`);
    setTitle(response.data.title);
    setStatus(response.data.status);
    setDescription(response.data.description);
    setDetailContent(response.data.detailcontent);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  return (
    <Container>
      <div className="ck-content">
        <HeaderBreadcrumb
          pathLink="/service"
          pathName="Service"
          pageName={`Service Detail ` + title}
        />
        <div className="flex my-4 gap-10">
          <PreviewImage preview={preview} src={preview} srcNone={noImage} />
          <div className="flex flex-col">
            <p className="font-semibold text-2xl uppercase">{title}</p>
            <p className="font-medium mt-2">Description</p>
            <p>{description}</p>
            <p className="font-medium mt-2">Detail Information</p>
            <p
              className="editor"
              dangerouslySetInnerHTML={{ __html: detailcontent }}
            ></p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewService;
