import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { FaSave, FaUserLock } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContainerForm } from "../../components/ui/Container";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const AddUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState();
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const saveUser = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("name", name);
    try {
      await axios.post(`${apiUrl}/user/register`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/user");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/user"
        pathName="User"
        pageName="Add New User"
      />
      <Form onSubmit={saveUser} title="Add Users">
        <div className="col-span-2">
          <Textfield
            type="text"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />

          <Textfield
            type="text"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Textfield
            type="text"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
          />

          <ButtonWithIcon
            type="submit"
            icon={FaSave}
            text="Save"
            className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
          />
        </div>
      </Form>
    </ContainerForm>
  );
};

export default AddUser;
