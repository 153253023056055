import React from "react";

export const FileImage = ({ title, onChange }) => {
  return (
    <div>
      <label htmlFor="image" className="block text-gray-700">
        {title}
      </label>
      <div>
        <input type="file" className="border-red-700" onChange={onChange} />
      </div>
    </div>
  );
};

export const PreviewImage = ({ preview, title, srcNone, src }) => {
  return (
    <>
      {preview ? (
        <div className="flex flex-col">
          <p className=" text-slate-700">{title}</p>
          <img src={src} className="w-48" alt="Preview Image" />
        </div>
      ) : (
        <div className="flex flex-col">
          <p className=" text-slate-700">{title}</p>
          <img src={srcNone} className="w-48" alt="Preview Image" />
        </div>
      )}
    </>
  );
};
