import React from "react";
import { motion } from "framer-motion";

export const Table = ({ children }) => {
  return (
    <motion.div
      className="container mx-auto p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <table className="table-auto min-w-full border-collapse border border-gray-200">
        {children}
      </table>
    </motion.div>
  );
};

export const TableHead = ({ children }) => {
  return (
    <thead className="bg-gray-100">
      <tr>{children}</tr>
    </thead>
  );
};
export const TableHeader = ({ HeaderTitle }) => {
  return (
    <th className="py-2 px-3 border text-sm text-gray-700 font-semibold">
      {HeaderTitle}
    </th>
  );
};

export const TableBody = ({ children }) => {
  return <tbody>{children}</tbody>;
};
export const TableRows = ({ children }) => {
  return <tr>{children}</tr>;
};

export const TableCells = ({ children, className }) => {
  const defaultClassName = "py-1 px-3 text-sm";

  return <td className={`${defaultClassName}  ${className}`}>{children}</td>;
};
