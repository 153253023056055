import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form } from "../../components/ui/Form";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Textfield } from "../../components/ui/Textfield";
import { FaAppStore, FaSave } from "react-icons/fa";
import { ButtonWithIcon } from "../../components/ui/Button";
import { ContainerForm } from "../../components/ui/Container";

const AddApplication = () => {
  const [name, setName] = useState("");
  const [shortname, setShortname] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  const saveApplication = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("shortname", shortname);
    formData.append("image", image);
    formData.append("url", url);
    try {
      await axios.post(`${apiUrl}/application`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/application");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContainerForm>
      <HeadingTitleForm
        title="Add New Application"
        icon={FaAppStore}
        color="text-blue-500"
      />
      <Form
        onSubmit={saveApplication}
        className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4 rounded-md border place-items-start "
      >
        <Textfield
          type="text"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <Textfield
          type="text"
          label="Shortname"
          value={shortname}
          onChange={(e) => setShortname(e.target.value)}
          placeholder="Shortname"
        />
        <Textfield
          type="text"
          label="URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="url"
        />
        <Textfield
          type="text"
          label="image"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          placeholder="Logo"
        />

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Save"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default AddApplication;
