import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import noImage from "../../assets/noimage.png";
import { HeadingTitleForm } from "../../components/ui/Heading";
import { Form } from "../../components/ui/Form";
import { Textfield } from "../../components/ui/Textfield";
import { Checkbox } from "../../components/ui/Checkbox";
import { FileImage, PreviewImage } from "../../components/ui/FileImage";
import { ButtonWithIcon } from "../../components/ui/Button";
import { FaSave, FaSuitcase } from "react-icons/fa";
import DateTime from "../../components/ui/DateTime";
import { ContainerForm } from "../../components/ui/Container";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HeaderBreadcrumb } from "../../components/ui/BreadCrump";

const EditCareer = () => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState();
  const [description, setDescription] = useState();
  const [closingdate, setClosingDate] = useState(new Date());
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API;

  useEffect(() => {
    getCareerById();
  }, []);

  const getCareerById = async () => {
    const response = await axios.get(`${apiUrl}/career/${id}`);
    setTitle(response.data.title);
    setStatus(response.data.status);
    setDescription(response.data.description);
    setClosingDate(response.data.closingdate);
    setImage(response.data.image);
    setPreview(response.data.url);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateCareer = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("status", status);
    formData.append("description", description);
    formData.append("closingdate", closingdate);
    formData.append("title", title);
    try {
      await axios.put(`${apiUrl}/career/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/career");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = () => {
    setStatus(!status);
    console.log(status);
  };

  const handleDateChange = (date) => {
    setClosingDate(date);
  };
  return (
    <ContainerForm>
      <HeaderBreadcrumb
        pathLink="/career"
        pathName="Career"
        pageName="Edit Career"
      />
      <Form onSubmit={updateCareer} title="Update Career">
        <Textfield
          type="text"
          label="Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Career Title"
        />
        <DateTime
          selected={new Date(closingdate)}
          onChange={(e) => handleDateChange(closingdate)}
          label="Closing Date"
        />

        <div>
          <PreviewImage
            title="Image Preview"
            preview={preview}
            src={preview}
            srcNone={noImage}
          />
          <FileImage title="Image" onChange={loadImage} />
        </div>

        <Checkbox
          type="checkbox"
          name="status"
          title="status"
          checked={status}
          onChange={handleCheckboxChange}
          alert="Is this Service still Active?"
          positif="Yes"
          negative="No"
        />

        <div className="col-span-2">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>

        <ButtonWithIcon
          type="submit"
          icon={FaSave}
          text="Update Data"
          className="bg-blue-600  hover:bg-blue-700 focus:ring-blue-300 my-4"
        />
      </Form>
    </ContainerForm>
  );
};

export default EditCareer;
