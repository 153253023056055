import React from "react";
import { FaArrowDown } from "react-icons/fa";

export const Option = ({
  title,
  onClickButtonTrigers,
  onClickButtonOption,
  isOpen,
  options,
  limit,
}) => {
  return (
    <div>
      <div className="flex gap-2 items-center justify-between px-6">
        <p className="text-gray-700">{title}</p>

        <button
          onClick={onClickButtonTrigers}
          type="button"
          className="flex gap-2 mx-auto font-semibold justify-center items-center rounded-md border border-gray-300 px-4 py-2 bg-white text-sm  text-gray-600 hover:bg-gray-50 focus:outline-none focus:border-gray-300 focus:ring focus:ring-gray-200 active:bg-gray-200"
          id="options-menu"
          aria-expanded={isOpen ? "true" : "false"}
          aria-haspopup="listbox"
        >
          <b>{limit}</b> Data
          <FaArrowDown className="h-4 w-4 mr" />
        </button>
      </div>
      <div
        className="origin-top-right absolute right-14 mt-2 w-24 rounded-md bg-white ring-1 ring-black ring-opacity-5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {isOpen && (
          <div className="py-1" role="none">
            {options.map((option) => (
              <button
                key={option}
                onClick={() => onClickButtonOption(option)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                <b>{option}</b> Data
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
