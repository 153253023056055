import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FaEdit,
  FaFileDownload,
  FaPlusSquare,
  FaUserLock,
} from "react-icons/fa";
import { Container } from "../../components/ui/Container";
import { Heading } from "../../components/ui/Heading";
import { ButtonOnlyIcon, ButtonWithIcon } from "../../components/ui/Button";
import Pagination from "../../components/ui/Pagination";
import { FormSearch } from "../../components/BigComponent/FormSearch";
import { Option } from "../../components/ui/Option";
import {
  Table,
  TableBody,
  TableCells,
  TableHead,
  TableHeader,
  TableRows,
} from "../../components/ui/Table";
import { RowsPagination } from "../../components/ui/RowsPagination";
import DeleteButton from "../../components/BigComponent/DeleteButton";
import { TitleList } from "../../components/BigComponent/TitleList";
import { FilterList } from "../../components/BigComponent/FilterList";

const ListUsers = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const [isOpen, setIsOpen] = useState(false);

  const options = [5, 10, 50, 100];
  const handleOptionChange = (option) => {
    setLimit(option);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    getUsers();
  }, [page, keyword, limit]);

  const getUsers = async () => {
    const response = await axios.get(
      `${apiUrl}/user?username=${keyword}&page=${page}&limit=${limit}`
    );

    setUsers(response.data.data);
    setPage(response.data.current_page);
    setPages(response.data.total_page);
    setRows(response.data.total_data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const searchData = (e) => {
    e.preventDefault();
    setKeyword(query);
  };

  const handleDeleteButton = (id) => {
    const updatedData = users.filter((item) => item.id !== id);
    setUsers(updatedData);
  };

  return (
    <Container>
      <TitleList
        title="Data User"
        icon={FaUserLock}
        text="Add"
        rows={users.length}
        linkTo="/user/add"
      />

      <div className="border rounded-lg pb-4">
        <div className="p-6">
          <FilterList
            onSubmitForm={searchData}
            value={query}
            onChangeItem={(e) => setQuery(e.target.value)}
            placeholder="Type username"
            options={options}
            onClickButtonOption={handleOptionChange}
            onClickButtonTrigers={toggleDropdown}
            isOpen={isOpen}
            limit={limit}
          />
        </div>
        <hr />

        <Table>
          <TableHead>
            <TableHeader HeaderTitle="No" />
            <TableHeader HeaderTitle="Username" />
            <TableHeader HeaderTitle="Name" />
            <TableHeader HeaderTitle="Action" />
          </TableHead>
          {users.length === 0 ? (
            <TableCells className="text-red-700 font-bold flex justify-center items-center mx-auto col-span-4">
              <p> Tidak ada data yang ditemukan</p>
            </TableCells>
          ) : (
            <TableBody>
              {users.map((item, index) => (
                <TableRows>
                  <TableCells className="text-center"> {index + 1}</TableCells>
                  <TableCells> {item.username}</TableCells>
                  <TableCells> {item.name}</TableCells>
                  <TableCells>
                    <div className="flex justify-center items-center">
                      <Link to={`/user/edit/${item.id}`}>
                        <ButtonOnlyIcon icon={FaEdit} />
                      </Link>

                      <DeleteButton
                        id={item.id}
                        api="user"
                        name={item.username}
                        title="User"
                        onDelete={handleDeleteButton}
                      />
                    </div>
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          )}
        </Table>
        <div className="flex gap-2 mx-auto justify-between px-6">
          <div className="flex gap-2">
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={handlePageChange}
            />
          </div>
          <RowsPagination page={page} pages={pages} />
        </div>
      </div>
    </Container>
  );
};

export default ListUsers;
