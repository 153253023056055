import React, { useState } from "react";
import DatePicker from "react-datepicker";

const DateTime = ({ selected, onChange, label }) => {
  return (
    <div>
      <p className=" text-gray-700 font-semibold">{label}</p>
      <DatePicker
        name="datetime"
        id="datetime"
        showIcon
        selected={selected}
        onChange={onChange}
      />
    </div>
  );
};

export default DateTime;
